<template>
  <div>
    <app-navbar></app-navbar>
    <app-wrapper>
      <div class="set_password_form">
        <div class="w-full max-w-lg">
          <ui-card v-loading="loading" :heading="$t('auth.set_new_password')">
            <ui-form
              :submit-text="$t('auth.set_new_password')"
              @submit="handleSubmit"
              :submitDisabled="
                !hasLength ||
                !hasSmall ||
                !hasCapital ||
                !hasNumber ||
                !hasSpecial ||
                !isConfirmed ||
                !tokenIsValid
              "
              hide-cancel
            >
              <ui-form-field :label="$t('fields.password')">
                <el-input
                  type="password"
                  v-model="password"
                  :disabled="!tokenIsValid"
                ></el-input>
              </ui-form-field>

              <ui-form-field :label="$t('fields.password_confirmation')">
                <el-input
                  type="password"
                  v-model="confirmation"
                  :disabled="!tokenIsValid"
                ></el-input>
              </ui-form-field>

              <ui-form-feedback>
                <ul>
                  <li class="flex">
                    <svg
                      v-if="hasLength"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasLength"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>
                      {{ $t("validation.minimum_characters", { min: 6 }) }}
                    </div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="hasCapital"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasCapital"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.has_uppercase_letter") }}</div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="hasSmall"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasSmall"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.has_lowercase_letter") }}</div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="hasNumber"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasNumber"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.has_number") }}</div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="hasSpecial"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasSpecial"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.has_special_character") }}</div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="isConfirmed"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!isConfirmed"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.matches_confirmation") }}</div>
                  </li>
                </ul>
              </ui-form-feedback>
            </ui-form>
          </ui-card>

          <ui-card
            v-loading="loading"
            :heading="$t('auth.resend_token')"
            v-if="tokenError"
            class="mt-2"
          >
            <ui-form
              hideCancel
              @submit="onResendToken"
              :submitText="$t('actions.resend_token')"
            >
              <ui-form-field :label="$t('fields.email')">
                <el-input type="text" v-model="tokenEmail"></el-input>
              </ui-form-field>
            </ui-form>
          </ui-card>
        </div>
      </div>
    </app-wrapper>
  </div>
</template>

<script>
import UiCard from "@/components/ui/UiCard";
import UiForm from "@/components/ui/UiForm";
import UiFormField from "@/components/ui/UiFormField";
import UiFormFeedback from "@/components/ui/UiFormFeedback";
import {
  setPassword,
  resendEmailConfirmation,
  validateSetPasswordToken,
} from "@/app/auth/api";
import AppWrapper from "@/components/app/AppWrapperGuest";
import AppNavbar from "@/components/app/AppNavbarGuest";

export default {
  components: {
    AppNavbar,
    AppWrapper,
    UiCard,
    UiForm,
    UiFormField,
    UiFormFeedback,
  },

  data() {
    return {
      password: "",
      confirmation: "",
      token: "",
      userId: "",
      tokenEmail: "",
      tokenError: false,
      reset: false,
      loading: false,
      tokenIsValid: true,
    };
  },

  computed: {
    hasLength() {
      return this.password.length >= 6;
    },
    hasSpecial() {
      return !!this.password.match(/[^\w^\d]/);
    },
    hasSmall() {
      return !!this.password.match(/[a-å]/);
    },
    hasCapital() {
      return !!this.password.match(/[A-Z]/);
    },
    hasNumber() {
      return !!this.password.match(/[\d]/);
    },
    isConfirmed() {
      return this.password.length > 0 && this.password === this.confirmation;
    },
  },

  methods: {
    async handleSubmit() {
      if (
        !this.hasLength ||
        !this.hasSpecial ||
        !this.hasSmall ||
        !this.hasCapital ||
        !this.hasNumber ||
        !this.isConfirmed
      )
        return;
      this.loading = true;
      try {
        const payload = {
          token: this.token,
          userid: this.userId,
          password: this.password,
          reset: this.reset,
        };
        const result = await setPassword(payload);
        this.loading = false;
        if (result.resendToken) {
          this.tokenError = true;
          this.$message({
            message: this.$t("auth.set_new_password_token_error"),
            type: "error",
            duration: 10000,
          });
        } else {
          this.$message({
            message: this.$t("auth.set_new_password_success"),
            type: "success",
          });
          this.$router.push({ name: "login" });
        }
      } catch (error) {
        this.$message({ message: this.$t("errors.general"), type: "error" });
        this.loading = false;
      }
    },

    async onResendToken() {
      const payload = {
        token: this.token,
        userid: this.userId,
        reset: false,
        email: this.tokenEmail,
      };
      this.loading = true;
      try {
        // eslint-disable-next-line no-unused-vars
        const result = await resendEmailConfirmation(payload);
        this.loading = false;
        this.$message({
          message: this.$t("auth.set_new_password_token_sendt"),
          type: "success",
          duration: 10000,
        });
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t("errors.general"), type: "error" });
      }
    },
  },

  created() {
    const query = this.$router.history.current.query;
    this.token = query.token;
    this.userId = query.userid;
    this.reset =
      query.reset === "true" || query.reset === "True" ? true : false;

    const isResetRequest = this.reset ? true : false;
    validateSetPasswordToken(this.userId, this.token, isResetRequest)
      .then((isValid) => (this.tokenIsValid = isValid))
      .then(() => {
        if (!this.tokenIsValid) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: this.$t("auth.set_new_password_token_invalid"),
            type: "error",
            duration: -1, // never disappear
            offset: 100,
          });
        }
      });
  },
};
</script>
<style scoped>
.flex {
  display: flex;
}

.icon {
  @apply w-4 h-4 mr-4 block text-gray-500 fill-current;
}

.valid {
  fill: green;
}

.invalid {
  fill: red;
}

.set_password_form {
  margin-top: 5em;
  width: 30em;
}
</style>
